import { div } from "framer-motion/client";
import React from "react";
import image from "../assets/home/Anulom Vilom Pranayama Discover Its Power with Yog Yatra.jpg";
import "./blogpage.css";
import Navbar from "./navbar";
import Footer from "./footer";
import blog2img from "../assets/home/howyogatransformsyourbodyandmind.jpg";
import masteranulomvilom from "../assets/home/masteringanulomvilom.jpeg";
import { Link } from "react-router-dom";
import RandomBlogs from "./Randomblog";

function Blogpage() {
  const blogPost = {
    title: "Anulom Vilom Pranayama: Discover Its Power with Yog Yatra",
    author: "Yog Yatra",
    date: "October 4, 2024",
    content: `
         
        `,
    image: image,
  };

  function addot() {
    const heading = document.getElementsByTagName("h3");
  }
  addot();

  return (
    <div className="bg-white min-h-screen">
      <Navbar />
      <div className="max-w-4xl mx-auto py-12 px-4">
        {/* Blog Header */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            {blogPost.title}
          </h1>
          <p className="text-gray-600">
            By <span className="font-semibold">{blogPost.author}</span> on{" "}
            {blogPost.date}
          </p>
        </div>

        {/* Featured Image */}
        <div className="mb-8">
          <img
            src={blogPost.image}
            alt={blogPost.title}
            className="w-full h-64 object-cover rounded-lg shadow-md"
          />
        </div>

        <div className="blog-container">
  <h1 className="darktext">Anulom Vilom Pranayama: Your Path to Wellness | Yog Yatra</h1>
  <p>Explore Anulom Vilom Pranayama’s benefits for stress relief, focus, and health. Join Yog Yatra for a transformative experience.</p>

  <h2>What is Anulom Vilom Pranayama?</h2>
  <p>Anulom Vilom is a breathing exercise that involves inhaling and exhaling through alternate nostrils. This technique balances the body’s energy, enhances lung capacity, and promotes overall well-being. It’s an integral part of yoga, recognized for its ability to bring peace and clarity.</p>

  <h2>Benefits of Anulom Vilom Pranayama</h2>
  <h3>1. Reduces Stress and Anxiety</h3>
  <p>Anulom Vilom calms your nervous system, lowers cortisol levels, and promotes relaxation, helping you to unwind and rejuvenate.</p>
  <h3>2. Improves Respiratory Health</h3>
  <p>This practice strengthens lung function and increases oxygen intake, beneficial for those with asthma or respiratory issues.</p>
  <h3>3. Enhances Focus and Concentration</h3>
  <p>Anulom Vilom helps clear mental clutter, sharpening your concentration and efficiency.</p>
  <h3>4. Promotes Emotional Balance</h3>
  <p>Regular practice stabilizes emotions and fosters inner peace, helping you navigate life with grace.</p>
  <h3>5. Boosts Energy Levels</h3>
  <p>Balancing your body’s energy helps combat fatigue, leaving you feeling energized and alert.</p>
  <h3>6. Improves Sleep Quality</h3>
  <p>Calming the mind and reducing anxiety, Anulom Vilom can enhance your sleep quality, leading to more restful nights.</p>

  <h2>How to Practice Anulom Vilom</h2>
  <ol>
    <li>Find a Comfortable Position: Sit cross-legged with your spine straight and shoulders relaxed.</li>
    <li>Hand Position: Use your right hand. Place your thumb on your right nostril and your ring finger on your left.</li>
    <li>Inhale: Close your right nostril with your thumb and inhale through your left.</li>
    <li>Switch: Close your left nostril with your ring finger and open your right nostril.</li>
    <li>Exhale: Exhale slowly through the right nostril.</li>
    <li>Repeat: Continue this pattern for 5-10 minutes, focusing on your breath.</li>
  </ol>

  <h2>Tips for Beginners</h2>
  <ul>
    <li>Start Slowly: Begin with a few minutes daily, gradually increasing duration.</li>
    <li>Listen to Your Body: Pause if you feel discomfort.</li>
    <li>Be Consistent: Aim for daily practice to fully experience the benefits.</li>
  </ul>

  <h2>Incorporating Anulom Vilom into Your Daily Routine</h2>
  <p>For maximum benefits, consider adding Anulom Vilom to your morning or evening rituals, possibly pairing it with other yoga poses or mindfulness practices.</p>

 
</div>

        {/* Related Posts */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Related Posts
          </h2>
          <RandomBlogs />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogpage;
