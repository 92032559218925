import React from "react";
import image from "../assets/home/yogaforbeginners.jpeg"; // Add the correct image path here
import "./blogpage.css";
import Navbar from "./navbar";
import Footer from "./footer";
import blog2img from "../assets/home/howyogatransformsyourbodyandmind.jpg";
import masteranulomvilom from "../assets/home/masteringanulomvilom.jpeg";
import { Link } from "react-router-dom";
import RandomBlogs from "./Randomblog";

function Blogpage3() {
  const blogPost = {
    title: "Yoga for Beginners: How to Get Started on Your Journey",
    author: "Yog Yatra",
    date: "October 6, 2024",
    content: ``,
    image: image,
  };

  return (
    <div className="bg-white min-h-screen">
      <Navbar />
      <div className="max-w-4xl mx-auto py-12 px-4">
        {/* Blog Header */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            {blogPost.title}
          </h1>
          <p className="text-gray-600">
            By <span className="font-semibold">{blogPost.author}</span> on{" "}
            {blogPost.date}
          </p>
        </div>

        {/* Featured Image */}
        <div className="mb-8">
          <img
            src={blogPost.image}
            alt={blogPost.title}
            className="w-full h-64 object-cover rounded-lg shadow-md"
          />
        </div>

        <div className="blog-container">
          <h1>Your Guide to Starting Yoga</h1>
          <p>
            Embarking on the journey of yoga can be both exciting and
            transformative. Yoga is a practice that connects the mind, body, and
            breath, offering benefits that span physical fitness, emotional
            balance, and mental clarity. If you’re new to yoga and wondering how
            to get started, this guide will help ease you into the practice with
            tips, essential poses, and a mindful approach.
          </p>

          <h2>1. Find the Right Type of Yoga</h2>
          <p>
            Yoga offers various styles, each with unique focuses and benefits.
            For beginners, Hatha Yoga, which emphasizes basic poses and slow,
            controlled movements, is a great place to start. Vinyasa Flow is
            another accessible option, linking breath with movement in a
            sequence of poses.
          </p>

          <h3>Hatha Yoga</h3>
          <p>
            Known for its gentle pace, Hatha Yoga introduces foundational
            postures that improve flexibility, strength, and balance. This style
            encourages mindful alignment and deep breathing, making it ideal for
            beginners who want to understand basic yoga principles.
          </p>

          <h2>2. Focus on Basic Poses</h2>
          <p>
            Start with basic poses that build strength and flexibility without
            overwhelming your body. Common beginner poses like Downward-Facing
            Dog, Warrior I, and Child’s Pose provide an excellent foundation for
            your practice.
          </p>

          <h3>Downward-Facing Dog (Adho Mukha Svanasana)</h3>
          <p>
            A signature yoga pose, Downward Dog stretches the hamstrings,
            calves, and spine while building strength in the arms and legs. To
            get into this pose, start on your hands and knees, tuck your toes,
            and lift your hips towards the sky, forming an inverted "V" shape
            with your body. Ensure your fingers are spread wide and your heels
            gently press toward the floor.
          </p>

          <h2>3. Remember the Importance of Breath</h2>
          <p>
            In yoga, breath is just as important as the physical poses. The
            coordination of breath with movement—called "Pranayama"—is at the
            core of yoga practice. It’s essential to breathe deeply and steadily
            during each pose. One simple breathing technique you can practice is
            Ujjayi breath, also known as "victorious breath," which helps calm
            the mind and focus during your practice.
          </p>

          <h3>How to Practice Ujjayi Breath</h3>
          <p>
            Sit comfortably with a straight spine, inhale deeply through the
            nose, and gently constrict the back of your throat as you exhale,
            creating a soft "ocean wave" sound. Repeat this process, syncing
            your breath with your movements.
          </p>

          <h2>4. Create a Consistent Practice</h2>
          <p>
            The key to progressing in yoga is consistency. Even short,
            10-15-minute daily sessions can make a huge difference in how you
            feel physically and mentally. Set aside time each day to focus on
            simple poses, breathing exercises, or mindfulness meditation.
            Gradually, your flexibility and strength will improve, and you'll
            develop a deeper connection to your practice.
          </p>

          <h2>5. Be Patient and Kind to Yourself</h2>
          <p>
            Yoga is a journey, not a destination. It’s important to remember
            that progress takes time, and every body is different. Don’t be too
            hard on yourself if a pose feels challenging or if your flexibility
            takes time to improve. Approach each session with an open mind and
            be kind to yourself, celebrating small wins along the way.
          </p>

          <h2>Conclusion</h2>
          <p>
            Yoga for beginners is all about exploring the mind-body connection,
            focusing on the breath, and gradually building strength and
            flexibility. With the right mindset, consistency, and patience, you
            can begin your yoga journey with confidence. Whether you’re looking
            to improve physical fitness or find inner peace, yoga has the power
            to transform your life in meaningful ways. So, roll out your mat and
            embrace the beautiful journey ahead!
          </p>
        </div>

        {/* Related Posts */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Related Posts
          </h2>
          <RandomBlogs />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogpage3;
