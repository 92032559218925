import yoga1 from "../assets/home/howyogatransformsyourbodyandmind.jpg";
import yoga2 from "../assets/home/top5yogaposesforstressrelief.jpeg";
import yoga3 from "../assets/home/yogaforbeginners.jpeg";
import yoga4 from "../assets/home/masteringanulomvilom.jpeg";

export const yogaBlogs = [
  {
    title: "How Yoga Transforms Both Body and Mind",
    description:
      "Explore the physical, mental, and emotional benefits of yoga, and how it can transform your entire life. This blog delves into how yoga enhances flexibility, strength, mental clarity, and stress relief.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "October 4, 2024",
    image: yoga1,
    link: "/blogs/How-yoga-transforms-body-and-mind",
  },
  {
    title: "The Top 5 Yoga Poses for Stress Relief and Relaxation",
    description:
      "Discover the best yoga poses for stress relief. Whether you're a beginner or experienced yogi, these poses will help you relax and find balance in your life.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "October 1, 2024",
    image: yoga2,
    link: "/blogs/top-5-yogaposes-for-stress-relief",
  },
  {
    title: "Yoga for Beginners: How to Get Started on Your Journey",
    description:
      "If you're new to yoga, this blog will guide you through the basics, including tips on choosing the right style, what to expect in your first class, and essential equipment for your practice.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "September 28, 2024",
    image: yoga3,
    link: "/blogs/How-to-get-started-on-your-journey",
  },
  {
    title: "Mastering Anulom Vilom Pranayama for Inner Peace",
    description:
      "Learn the step-by-step process of practicing Anulom Vilom Pranayama, and how this ancient breathing technique can bring mental clarity and inner peace.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "September 20, 2024",
    image: yoga4,
    link: "/blogs/mastering-anulom-vilom",
  },
];
