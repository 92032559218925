import React from "react";
import cross from "../assets/home/close.png";
import "./modal.css"; // Add appropriate CSS for the modal
import { CgClose } from "react-icons/cg";
import { IoSend } from "react-icons/io5";

const Modal = ({ isOpen, closeModal, handleSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>
          <CgClose />
        </button>
        <form className="w-full" onSubmit={handleSubmit}>
          <h2 className="modalheading">Join the Plan</h2>
          <div className="labelinputcontainer w-full">
            {/* <label>Name</label> */}
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="labelinputcontainer w-full">
            {/* <label>Email</label> */}
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="labelinputcontainer w-full">
            {/* <label>Phone</label> */}
            <input
              type="tel"
              name="phone"
              placeholder="Enter your phone number"
              required
            />
          </div>
          <div className="labelinputcontainer w-full">
            {/* <label>Select Plan</label> */}
            <select name="plan" required>
              <option value="Starter Plan">Starter Plan</option>
              <option value="Pro Plan">Pro Plan</option>
              <option value="Premium Plan">Premium Plan</option>
            </select>
          </div>
          <button type="submit" className="submit-btn">
            Submit <IoSend />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
