import blogimage1 from "../assets/home/Anulom Vilom Pranayama Discover Its Power with Yog Yatra.jpg";
import blogimage2 from "../assets/home/large_webp_motiv_thema-lehrerin-yogaeasy-header-.jpg.webp.png";
import blogimage3 from "../assets/home/large_webp_stimme-persoenlichkeit-irina-valentin-alex-xenia-bluhm-yogaeasy-header__1_.jpg.webp.png";

export const blogs = [
  {
    img: blogimage1,
    
    alt: "Pranayama Yoga",
    title:
      "Anulom Vilom Pranayama: Discover Its Power with Yog Yatra",
    link: "/blogs/Anulom Vilom Pranayama",
    linkText: "Read more ›",
  },
  {
    img: blogimage2,
    alt: "Erdung Yoga",
    title:
      "Unlock Holistic Well-being: Essential Yoga Tips ",
    link: "/blogs/top-5-yogaposes-for-stress-relief",
    linkText: "Read more ›",
  },
  {
    img: blogimage3,
    alt: "Element Erde Yoga",
    title:
      "Transform Your Yoga Journey: Expert Advice and Wellness",
    link: "/blogs/How-to-get-started-on-your-journey",
    linkText: "Read more ›",
  },
];
