import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./components/blog.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutUsPage from "./components/About";
import ContactForm from "./components/Contact";
import Blog from "./components/Blog";
import Cancellation from "./components/Cancellation";
import Blogpage from "./components/Blogpage";
import Blogpage2 from "./components/Blogpage2";
import Blogpage3 from "./components/Blogpage3";
import Blogpage4 from "./components/Blogpage4";
import ScrollToTop from "./components/Scrolltotop"; // Updated component

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/about",
    element: <AboutUsPage />,
  },
  {
    path: "/blogs",
    children: [
      {
        path: "all-blogs",
        element: <Blog />,
      },
      {
        path: "Anulom Vilom Pranayama",
        element: <Blogpage />,
      },
      {
        path: "top-5-yogaposes-for-stress-relief",
        element: <Blogpage2 />,
      },
      {
        path: "How-to-get-started-on-your-journey",
        element: <Blogpage3 />,
      },
      {
        path: "mastering-anulom-vilom",
        element: <Blogpage4 />,
      },
    ],
  },
  {
    path: "/cancellationpolicy",
    element: <Cancellation />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <ScrollToTop /> {/* Ensure ScrollToTop is inside RouterProvider */}
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
