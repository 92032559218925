import React, { useState, useEffect } from "react";
import { yogaBlogs } from "../Arrays/Yogablogs"; // adjust the path accordingly

const RandomBlogs = ({ numOfBlogs }) => {
  const [randomBlogs, setRandomBlogs] = useState([]);

  // Function to select a specified number of random unique blogs from the array
  const getRandomBlogs = () => {
    const shuffledBlogs = yogaBlogs.sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffledBlogs.slice(0, 2); // Return the specified number of blogs
  };

  useEffect(() => {
    // Set random blogs when the component mounts
    setRandomBlogs(getRandomBlogs());
  }, [2]); // Recalculate if numOfBlogs changes

  return (
    <div
      className={`grid grid-cols-2 md:grid-cols-${
        numOfBlogs > 1 ? 2 : 1
      } gap-6 max-sm:grid-cols-1 w-full`}
    >
      {randomBlogs.map((blog, index) => (
        <div className="grid grid-cols-1 w-full gap-2">
          {/* Related Post 1 */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
            <img
              src={blog.image}
              alt={blog.alt}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h4 className="text-xl font-semibold text-gray-800">
                How Yoga Transforms Both Body and Mind
              </h4>
              <a
                href="#"
                className="inline-block mt-4 text-blue-600 hover:underline"
              >
                Read More →
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RandomBlogs;
