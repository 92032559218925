import React from "react";
import image from "../assets/home/top5yogaposesforstressrelief.jpeg";
import "./blogpage.css";
import Navbar from "./navbar";
import Footer from "./footer";
import blog2img from "../assets/home/howyogatransformsyourbodyandmind.jpg";
import masteranulomvilom from "../assets/home/masteringanulomvilom.jpeg";
import { Link } from "react-router-dom";
import RandomBlogs from "./Randomblog";

function Blogpage2() {
  const blogPost = {
    title: "The Path to Inner Peace: Yoga for Beginners",
    author: "Yog Yatra",
    date: "October 4, 2024",
    content: `
             
            `,
    image: image,
  };

  return (
    <div className="bg-white min-h-screen">
      <Navbar />
      <div className="max-w-4xl mx-auto py-12 px-4">
        {/* Blog Header */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            {blogPost.title}
          </h1>
          <p className="text-gray-600">
            By <span className="font-semibold">{blogPost.author}</span> on{" "}
            {blogPost.date}
          </p>
        </div>

        {/* Featured Image */}
        <div className="mb-8">
          <img
            src={blogPost.image}
            alt={blogPost.title}
            className="w-full h-64 object-cover rounded-lg shadow-md"
          />
        </div>

        <div className="blog-container">
          <h1>The Top 5 Yoga Poses for Stress Relief and Relaxation</h1>
          <p>
            In today’s fast-paced world, stress has become a common part of our
            lives. Fortunately, yoga offers a natural and effective way to
            relieve stress and promote relaxation. By focusing on mindful
            movement and controlled breathing, yoga helps activate the body’s
            relaxation response, reducing tension and calming the mind.
          </p>

          <h2>1. Child’s Pose (Balasana)</h2>
          <p>
            Child’s Pose is a resting posture that gently stretches the lower
            back, hips, and thighs while calming the mind. This pose helps
            relieve tension in the back and shoulders, making it an excellent
            option for stress relief. It encourages a sense of surrender and
            allows your body to rest.
          </p>

          <h3>How to Perform Child’s Pose:</h3>
          <p>
            Kneel on the floor with your big toes touching and sit back on your
            heels. Slowly lower your chest toward the floor, extending your arms
            in front of you. Rest your forehead on the mat and breathe deeply,
            holding this pose for as long as you like.
          </p>

          <h2>2. Cat-Cow Pose (Marjaryasana-Bitilasana)</h2>
          <p>
            The Cat-Cow Pose is a dynamic combination of two movements that help
            relieve tension in the spine and release stress. The gentle flow
            between these two postures stretches the back and neck while
            increasing flexibility and improving circulation.
          </p>

          <h3>How to Perform Cat-Cow Pose:</h3>
          <p>
            Start on all fours with your wrists directly under your shoulders
            and your knees under your hips. Inhale as you arch your back (Cow
            Pose), lifting your chest and tailbone upward. Exhale as you round
            your spine (Cat Pose), tucking your chin to your chest. Repeat this
            flow for 5-10 breaths.
          </p>

          <h2>3. Standing Forward Bend (Uttanasana)</h2>
          <p>
            Standing Forward Bend is a calming pose that stretches the
            hamstrings and lower back while helping to relieve stress and
            anxiety. The inversion of the head below the heart allows blood to
            flow to the brain, promoting relaxation and clarity of mind.
          </p>

          <h3>How to Perform Standing Forward Bend:</h3>
          <p>
            Stand with your feet hip-width apart and bend forward from the hips,
            letting your torso hang over your legs. Keep your knees slightly
            bent if needed. Allow your head and neck to relax as you hold this
            position for several deep breaths.
          </p>

          <h2>4. Legs-Up-the-Wall Pose (Viparita Karani)</h2>
          <p>
            Legs-Up-the-Wall Pose is a deeply relaxing inversion that helps calm
            the nervous system and reduce stress. This pose gently stretches the
            hamstrings and lower back while promoting circulation and reducing
            fatigue.
          </p>

          <h3>How to Perform Legs-Up-the-Wall Pose:</h3>
          <p>
            Sit next to a wall and swing your legs up as you lie on your back,
            allowing your legs to rest vertically against the wall. Extend your
            arms out to the sides, close your eyes, and breathe deeply. Stay in
            this pose for 5-10 minutes.
          </p>

          <h2>5. Corpse Pose (Savasana)</h2>
          <p>
            Corpse Pose is often the final relaxation pose in yoga practice and
            is essential for calming the mind and body. This simple posture
            allows the body to fully relax, letting go of all tension and
            stress.
          </p>

          <h3>How to Perform Corpse Pose:</h3>
          <p>
            Lie flat on your back with your arms at your sides, palms facing up.
            Close your eyes and focus on your breath, allowing each part of your
            body to relax completely. Stay in Savasana for at least 5-10 minutes
            to fully experience the relaxation benefits.
          </p>

          <h2>Conclusion</h2>
          <p>
            Yoga offers a natural, effective way to manage stress and promote
            relaxation. Incorporating these poses into your daily routine can
            help you find balance and peace amidst the challenges of modern
            life. Whether you're dealing with anxiety, tension, or simply
            looking to unwind, these five yoga poses will guide you toward a
            more relaxed and peaceful state of mind.
          </p>
        </div>

        {/* Related Posts */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Related Posts
          </h2>
<RandomBlogs/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogpage2;
