import React from "react";
import Navbar from "./navbar";
import Section from "../Modules/Section";
import "./home.css";
import Footer from "./footer";
import "./blog.css";
import { Link } from "react-router-dom";
import Button from "../Modules/Button";
import { yogaBlogs } from "../Arrays/Yogablogs";

function Blog() {
  return (
    <>
      <Navbar />
      <Section classname="w-full flex bloghero flex-col justify-center items-center bg-cover bg-no-repeat"></Section>
      <Section>
        <h1 className="text-5xl font-bold">Our Blogs</h1>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 w-full max-sm:px-3">
            <div className="mx-auto max-w-4xl lg:text-center pb-12 pt-4">
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Welcome to the Yog Yatra Blog – your go-to resource for
                insightful articles on yoga, wellness, and holistic living.
                Whether you're just starting your yoga journey or looking to
                deepen your practice, explore our curated content to inspire,
                educate, and guide you every step of the way.{" "}
              </p>
            </div>
            <div className=" w-full h-full justify-between items-center grid grid-cols-2 gap-4 max-sm:grid-cols-1">
              {yogaBlogs.map((element) => (
                <div
                  style={{
                    backgroundImage: `linear-gradient(#fff, #fff),url(${element.image})`,
                  }}
                  className="flex justify-end items-center flex-col h-auto w-full bg-center bg-cover bg-no-repeat rounded-lg gap-3 border shadow-md shadow-gray-100 py-2 px-2"
                >
                  <div className="gap-4 flex flex-col bg-white">
                    <div
                      className="bg-center bg-cover bg-no-repeat h-72 w-full rounded-t-xl px-3 py-3"
                      style={{
                        backgroundImage: `linear-gradient(#fff, #ffffff3f),url(${element.image})`,
                      }}
                    >
                      <h2 className="darktext text-2xl">{element.title}</h2>
                    </div>
                    <div className="px-3 flex flex-col gap-3">
                      <p>{element.description}</p>
                      <Button
                        classname="w-1/4"
                        bgcolor="transparent"
                        textcolor="#3d1818"
                      >
                        <Link className="" to={element.link}>
                          Read more...
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="w-full flex gap-2 bg-white items-center py-1 px-2">
                    <p className="text-sm">Written by: {element.writtenBy}</p>
                    <p className="text-sm">on {element.writtenOn}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}

export default Blog;
