import React from "react";
import { motion } from "framer-motion";

function Button({ children, classname, textcolor = "#fff",onclick, iconmame = "MdOutlineNavigateNext", bgcolor = "#3D1818",animationbgcolor = "#f5928b", animationtextcolor = "#fff" }) {
  return (
    <motion.button
    onClick={onclick}
      whileHover={{ backgroundColor: animationbgcolor, color: animationtextcolor }}
      onHoverStart={(e) => {}}
      onHoverEnd={(e) => {}}
      transition={{duration: .5,
        type: "spring"
      }}
      className={`py-2 px-5 flex items-center mt-4 gap-3 rounded-full justify-around flex-row ${classname} text-sm max-sm:w-1/2`}
      style={{ color: `${textcolor}`, backgroundColor: `${bgcolor}`}}
    >
      {children}
    </motion.button>
  );
}

export default Button;
